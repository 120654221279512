<template>
  <div class="footer primary">
    Desarrollado por <a href="http://www.calaverita.tech">Calaverita.tech</a>
  </div>
</template>

<script>
  export default {
    name: "Footer",
  };
</script>

<style lang="scss" scoped>
  .footer {
    text-align: center;
    color: rgba(255, 255, 255, 0.63);
    font-size: 0.7rem;
    padding: 0.1rem 0;
    a {
      text-decoration: none;
      color: rgba(255, 255, 255, 0.63);
    }
  }
</style>
